import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from './Layout';

const Completed = (props) => (
	<Layout title="app.title" {...props}>
		<div className="content form login">
			<div className="content-inner">
				<h1 className="smaller">
					<FormattedMessage id="completed.title"></FormattedMessage>
				</h1>
				<p className="narrow">
					<FormattedMessage id="completed.text"></FormattedMessage>
				</p>
				<p>
					<a className="link white" href="mailto:integritaetspruefungextern.partnerstelle@swisscom.com">
						<FormattedMessage id="email-send" />
					</a>
				</p>
			</div>
		</div>
	</Layout>
);

export default Completed;
